.root {
	padding: 0 0 30px;
}

.cards {
	display: grid;
	gap: 8px;
}

.card {
	background-color: var(--vkws_landings--color--raisin-black);
	border-radius: 24px;
	display: grid;
	grid-template-columns: auto 120px;
	gap: 8px;
	overflow: hidden;
}

.title {
	margin: 0;
	padding: 20px 0 16px 20px;
	font-size: 15px;
	line-height: 21px;
}

.card:nth-child(2n) {
	grid-template-columns: 120px auto;
}

.card:nth-child(2n) .image-wrapper {
	order: -1;
}

.card:nth-child(2n) .title {
	padding: 20px 20px 16px 0;
}

@media screen and (min-width: 1024px) {
	.root {
		width: 884px;
	}

	.cards {
		grid-template-columns: repeat(3, 1fr);
	}

	.card {
		grid-template-columns: auto;
	}

	.title {
		text-align: center;
		padding: 30px 30px 0 30px;
		font-size: 20px;
		line-height: 28px;
	}

	.image-wrapper {
		height: 140px;
		margin-top: auto;
		text-align: center;
	}

	.card:nth-child(2n) {
		grid-template-columns: auto;
	}

	.card:nth-child(2n) .title {
		padding: 0 30px 30px 30px;
	}

	.card:nth-child(2n) .image-wrapper {
		margin-top: 0;
		margin-bottom: auto;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		padding: 80px 0 20px;
		width: 1240px;
	}

	.title {
		font-size: 28px;
		line-height: 39px;
		padding: 36px 36px 0 36px;
	}

	.image-wrapper {
		height: 200px;
	}

	.card:nth-child(2n) .title {
		padding: 0 36px 36px 36px;
	}
}
